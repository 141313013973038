var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "topTabs" },
    [
      _c(
        "q-tabs",
        {
          staticClass: "tabmenu-bg",
          attrs: {
            dense: "",
            "inline-label": "",
            "outside-arrows": "",
            "mobile-arrows": "",
            align: "left",
          },
          model: {
            value: _vm.topTab,
            callback: function ($$v) {
              _vm.topTab = $$v
            },
            expression: "topTab",
          },
        },
        [
          _c(
            "draggable",
            {
              staticClass: "topTab-drag",
              attrs: {
                list: _vm.visitedViews,
                "ghost-class": "topTab-drag-ghost",
              },
            },
            _vm._l(_vm.visitedViews, function (tag) {
              return _c(
                "q-tab",
                {
                  key: tag.path,
                  ref: "tag",
                  refInFor: true,
                  class: _vm.getFavoriteClass(tag.name),
                  attrs: { name: tag.path, label: tag.title },
                  on: {
                    click: function ($event) {
                      return _vm.movePath(tag.path)
                    },
                  },
                },
                [
                  _c(
                    "q-menu",
                    {
                      attrs: {
                        "transition-show": "scale",
                        "transition-hide": "scale",
                        "touch-position": "",
                        "context-menu": "",
                      },
                    },
                    [
                      _c(
                        "q-list",
                        { staticClass: "topTabContext", attrs: { dense: "" } },
                        [
                          _c(
                            "q-item",
                            {
                              directives: [
                                {
                                  name: "close-popup",
                                  rawName: "v-close-popup",
                                },
                              ],
                              attrs: { clickable: "" },
                              on: { click: _vm.allCloseTab },
                            },
                            [
                              _c(
                                "q-item-section",
                                [
                                  _c("q-icon", {
                                    staticClass: "text-dark",
                                    attrs: { name: "cancel" },
                                  }),
                                  _vm._v(
                                    " " + _vm._s(_vm.$label("LBL0000863")) + " "
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "q-item",
                            {
                              directives: [
                                {
                                  name: "close-popup",
                                  rawName: "v-close-popup",
                                },
                              ],
                              attrs: { clickable: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.OtherCloseTab(tag)
                                },
                              },
                            },
                            [
                              _c(
                                "q-item-section",
                                [
                                  _c("q-icon", {
                                    staticClass: "text-dark",
                                    attrs: { name: "highlight_off" },
                                  }),
                                  _vm._v(
                                    " " + _vm._s(_vm.$label("LBL0000864")) + " "
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "q-item",
                            {
                              directives: [
                                {
                                  name: "close-popup",
                                  rawName: "v-close-popup",
                                },
                              ],
                              attrs: { clickable: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.addFavoriteTab(tag)
                                },
                              },
                            },
                            [
                              _c(
                                "q-item-section",
                                [
                                  _c("q-icon", {
                                    staticClass: "text-dark",
                                    attrs: { name: "star" },
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.setFavoriteLabel(tag)) +
                                      " "
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          tag.fullPath != "/main/index" &&
                          tag.fullPath != "/main/indexDirector"
                            ? _c(
                                "q-item",
                                {
                                  directives: [
                                    {
                                      name: "close-popup",
                                      rawName: "v-close-popup",
                                    },
                                  ],
                                  attrs: { clickable: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openNewWindow(tag)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "q-item-section",
                                    [
                                      _c("q-icon", {
                                        staticClass: "text-dark",
                                        attrs: { name: "launch" },
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$label("LBL0000866")) +
                                          " "
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.isDefectManage()
                    ? _c(
                        "q-icon",
                        {
                          staticClass: "deltab3 text-default-psm-custom",
                          attrs: { name: "bug_report" },
                          on: {
                            click: function ($event) {
                              return _vm.openDefect(tag)
                            },
                          },
                        },
                        [
                          _c("q-tooltip", [
                            _vm._v(_vm._s(_vm.$label("LBL0000867"))),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  tag.meta.manual
                    ? _c(
                        "q-icon",
                        {
                          staticClass: "deltab2 text-orange-custom",
                          attrs: { name: "quiz" },
                          on: {
                            click: function ($event) {
                              return _vm.openManual(tag)
                            },
                          },
                        },
                        [
                          _c("q-tooltip", [
                            _vm._v(_vm._s(_vm.$label("LBL0000868"))),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "q-icon",
                    {
                      staticClass: "deltab text-default-psm-custom",
                      attrs: { name: "close" },
                      on: {
                        click: function ($event) {
                          return _vm.closeTag(tag)
                        },
                      },
                    },
                    [
                      _c("q-tooltip", [
                        _vm._v(_vm._s(_vm.$label("LBL0000869"))),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }