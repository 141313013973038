<!--
  목적 : 사업장 select box
  작성자 : kdh
  Detail :
  *
  examples:
  *
  -->
<template>
  <div class="fix-height">
    <q-input
      bottom-slots
      readonly :dense="dense"
      :label="convertLabel"
      filled
      class="customEquipClass"
      stack-label
      :placeholder="convertPlaceholder"
      :disable="disabled || !editable"
      :rules="!required ? null : [val => !!val || '']"
      v-model="textequipmentTypeName"
      >
      <template v-slot:label>
        <div class="row items-center all-pointer-events">
          <b>
            <i v-if="!required" class="pe-7s-note labelfrontIcon searchAreaLabelIcon"></i>
            <font class="formLabelTitle">{{convertLabel}}</font>
            <i v-if="required" class="material-icons labelfrontIcon text-requiredColor">check</i>
          </b>
        </div>
      </template>

      <template v-slot:append>
        <q-icon name="search" @click="click" class="cursor-pointer"/>
        <q-icon name="close" @click="reset" class="cursor-pointer"></q-icon>
      </template>
    </q-input>

    <q-dialog
      ref="equipClassDialog"
      persistent
      @show="onShow" 
      @hide="onHide"
      position="top"
      content-class="mini-dialog"
      v-model="isOpen">
      <q-card class="equipClass-card dialog-layout">
        <q-card-section class="row items-center q-pb-none px-2 py-1 bg-mini-dialog text-white dialog-header cursor-move">
          <div class="text-h6">
            <!-- 설비유형 -->
            {{$label('LBL0000522')}}
          </div>
          <q-space></q-space>
          <q-btn icon="done" flat round dense @click="selectequipClass"></q-btn>
          <q-btn icon="close" flat round dense v-close-popup></q-btn>
        </q-card-section>
        <q-item>
          <q-item-section>
            <q-item-label caption>
              <q-chip outline square color="primary" text-color="white">
                {{equipmentTypeName}}
              </q-chip>
              <q-input ref="filter" dense v-model="filter" label="Filter">
                <template v-slot:append>
                  <q-icon v-if="filter !== ''" name="clear" class="cursor-pointer" @click="resetFilter" />
                </template>
              </q-input>
            </q-item-label>
          </q-item-section>
        </q-item>
        <q-separator></q-separator>

        <q-card-section class="equipClass-content p-2">
          <!-- LBL0000523: 설비유형 정보가 없습니다. -->
          <!-- LBL0000514: 필터링 된 데이터가 없습니다. -->
          <q-tree
            ref="customEquipClass"
            :nodes="equipClassTree"
            node-key="equipmentTypeCd"
            label-key="equipmentTypeName"
            children-key="children"
            :no-nodes-label="$comm.getLangLabel('LBL0000523')"
            :no-results-label="$comm.getLangMessage('MSGNOFILTERING')"
            :filter="filter"
            :default-expand-all="defaultExpandAll"
            selected-color="primary"
            :selected.sync="selected"
          />
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import arrayToTree from 'array-to-tree';
import mixinCommon from './js/mixin-common'
export default {
  name: 'c-equip-class',
  mixins: [mixinCommon],
  props: {
    // TODO : 부모의 v-model의 값을 받아오는 속성
    value: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'edit',
    },
    editable: {
      type: Boolean,
      default: true,
    },
    // 필수 입력 여부
    required: {
      type: Boolean,
      default: false,
    },
    // 라벨 텍스트
    label: {
      type: String,
      default: 'LBL0000522', // 설비유형
    },
    // 사용 여부
    disabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    defaultExpandAll: {
      type: Boolean,
      default: true,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    plantCd: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      defaultData: [],
      equipClassTree: [],
      equipmentTypeCd: '',
      equipmentTypeName: '',
      textequipmentTypeName: '',
      placeholder: '선택',
      popoverVisible: false,
      beforePlantCd: '',
      roleAccess: false,
      vequipClassSubYn: '', // 하위설비유형포함 내부값
      /** kdh */
      isOpen: false,
      filter: '',
      selected: null,
      target: null,
      nodeDragg: null
    };
  },
  watch: {
    // TODO : 부모의 v-model 변경을 감시(예를 들면, db로부터 데이터를 조회 한 후 값을 바인딩 할 경우)
    value() {
      this.checkDefault();
    },
    selected() {
      let data = this.$refs['customEquipClass'].getNodeByKey(this.selected);

      this.equipmentTypeCd = this.selected;
      this.equipmentTypeName = data.equipmentTypeName;
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());

    this.init();
  },
  mounted() {},
  beforeDestory() {},
  methods: {
    init() {
      this.placeholder = this.type === 'search' ? 
        'LBL0000516' : // 전체
        'LBLSELECT'; // 선택
      this.$http.url = selectConfig.mdm.equipment.class.list.url;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.defaultData = _result.data;
        this.equipClassTree = this.convertTree(_result.data);
      },);
    },
    checkDefault() {
      let data = null;
      if (this.value) {
        this.placeholder = this.type === 'search' ? 
          'LBL0000516' : // 전체
          'LBLSELECT'; // 선택
        this.$http.url = this.$format(selectConfig.mdm.equipment.class.name.url, this.value);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          data = _result.data;
          if (!data) {
            this.equipmentTypeCd = null;
            this.equipmentTypeName = null;
          } else {
            this.equipmentTypeCd = data.equipmentTypeCd;
            this.equipmentTypeName = data.equipmentTypeName;
            this.textequipmentTypeName = this.equipmentTypeName;
            this.$emit('input', this.equipmentTypeCd);
            this.$emit('seteequipmentTypeName', this.equipmentTypeName);
          }
        },);
      }
    },
    /**
     * 메뉴 트리용 기초 정보를 Tree 메뉴로 변환
     */
    convertTree(_treeBases) {
      if (!_treeBases || _treeBases.length <= 0) return null;
      var tree = arrayToTree(_treeBases, {
        parentProperty: 'upEquipmentTypeCd',
        customID: 'equipmentTypeCd',
      });
      return tree;
    },
    click() {
      if (this.editable && !this.disabled) {
        this.isOpen = true;
      }
    },
    resetFilter () {
      this.filter = ''
      this.$refs.filter.focus()
    },
    reset() {
      this.textequipmentTypeName = '';
      this.equipmentTypeCd = '';
      this.equipmentTypeName = '';
      this.$emit('input', this.equipmentTypeCd);
      this.$emit('seteequipmentTypeName', this.equipmentTypeName);
    },
    selectequipClass() {
      if (this.equipmentTypeName) {
        this.textequipmentTypeName = this.equipmentTypeName;
        this.$emit('input', this.equipmentTypeCd);
        this.$emit('seteequipmentTypeName', this.equipmentTypeName);
      }
      this.$refs['equipClassDialog'].hide();
    },
    onShow() {
      let dialogElements = document.getElementsByClassName("mini-dialog");
      this.target = dialogElements[dialogElements.length - 1].querySelector(".dialog-layout");
      this.nodeDragg = this.target.querySelector(".dialog-header");
      this.nodeDragg.addEventListener("mousedown", this.onGrab);

      this.$emit("onShow");
    },
    onHide() {
      document.removeEventListener("mousemove", this.onDrag);
      document.removeEventListener("mouseup", this.onLetGo);
      this.nodeDragg.removeEventListener("mousedown", this.onGrab);
      this.$emit("onHide");
    },
    onDrag(e) {
      let originalStyles = window.getComputedStyle(this.target);
      this.target.style.left =
        parseInt(originalStyles.left) + e.movementX + "px";
      this.target.style.top = parseInt(originalStyles.top) + e.movementY + "px";

      if (parseInt(originalStyles.top) + e.movementY < 0) {
        this.target.style.top = "0px"
      }
      if (parseInt(originalStyles.top) + e.movementY + 60 > window.innerHeight) {
        this.target.style.top = (window.innerHeight - 60) + "px"
      }
    },
    onLetGo() {
      document.removeEventListener("mousemove", this.onDrag);
      document.removeEventListener("mouseup", this.onLetGo);
    },
    onGrab() {
      document.addEventListener("mousemove", this.onDrag);
      document.addEventListener("mouseup", this.onLetGo);
    }
  },
};
</script>
<style lang="sass">
.equipClass-card
  width: 100%
  max-width: 450px !important
  height: 100%
  max-height: 520px !important
  overflow-y: hidden !important
.equipClass-content
  height: 100%
  max-height: 360px !important
  overflow-y: auto
.equipClass-card
  .bg-dialogheader
    height: 40px
    .q-btn
      .q-btn__wrapper
        padding: 0px !important
  .text-h6
    font-size: 1.2em
    line-height: 1.2em
    font-weight: 700
.customEquipClass
  .q-field__native
    padding-left:3px
    font-size: 0.95em !important
  .q-field__label
    margin-left: 5px
  .q-field__native::placeholder
    font-size: 1em !important
  .material-icons
    font-size: 0.8rem
  .equipClassCloseIcon
    display: none
.customEquipClass:hover
  .equipClassCloseIcon
    display: block
.customEquipClass.q-field--dense .q-field__control, .customEquipClass.q-field--dense .q-field__marginal
  height: 33px !important
  min-height: 33px !important

.customEquipClass.q-field--filled .q-field__control
  border: 1px solid rgba(0,0,0,0.15) !important
  padding: 0px 8px 0px 2px

.customEquipClass.q-field--filled.q-field--readonly .q-field__control:before
  opacity: 0
</style>