<!--
목적 : select 기반 select 컴포넌트(Not Autocomplete)
Detail :
  comboConfig.js 정보를 참고하여 컴포넌트 렌더링시 select 데이터 조회 해서 option 추가
 *
examples:
 *
-->
<template>
  <div class="fix-height">
    <q-field
      class="customTag"
      stack-label
      filled
      color="orange-custom"
      :dense="dense"
      :disable="disabled || !editable"
      :label="convertLabel"
      :rules="[myRule]"
      v-model="items"
      >
      <template v-slot:label v-if="label">
        <div class="row items-center all-pointer-events">
          <b>
            <i v-if="!required" class="pe-7s-note labelfrontIcon searchAreaLabelIcon"></i>
            <font class="formLabelTitle">{{convertLabel}}</font>
            <i v-if="required" class="material-icons labelfrontIcon text-requiredColor">check</i>
          </b>
        </div>
      </template>
      <template v-slot:control>
        <q-chip
          v-for="(item, idx) in items"
          outline square
          :key="idx"
          :dense="dense"
          :removable="editable&&!disabled"
          :clickable="clickable"
          :color="item['color'] ? item['color'] : 'primary'"
          text-color="white"
          :icon="icon"
          :label="item[itemText]"
          :title="item[itemText]"
          v-model="item[itemValue]"
          @remove="state => remove(state, item)"
          @click="(e) => clickTag(item)"
        >
          <q-tooltip>{{ item[itemText] }}</q-tooltip>
        </q-chip>
        <q-chip
          v-if="editable&&!disabled&&isAdd"
          :dense="dense"
          outline square
          clickable
          color="orange"
          text-color="white"
          icon="add"
          :label="$comm.getLangLabel(addLabel)"
          :title="$comm.getLangLabel(addLabel)"
          @click="add"
        />
      </template>
    </q-field>
  </div>
</template>

<script>
import mixinCommon from './js/mixin-common'
export default {
  /* attributes: name, components, props, data */
  name: 'c-tag',
  mixins: [mixinCommon],
  props: {
    name: {
      type: String,
    },
    // TODO : 부모의 v-model의 값을 받아오는 속성
    value: {
      type: [Array],
      default: () => [],
    },
    label: {
      // 부모로 부터 받아온 라벨 정보
      type: String,
      default: '',
    },
    // 쓰기 권한 여부
    editable: {
      type: Boolean,
      default: true,
    },
    itemText: {
      type: String,
      required: '',
    },
    itemValue: {
      type: String,
      required: '',
    },
    disabled: {
      type: Boolean,
    },
    // 필수 입력 여부
    required: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    addLabel: {
      type: String,
      default: 'LBL0000568', // 추가
    },
    icon: {
      type: String,
      default: 'precision_manufacturing',
    },
    isAdd: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      items: [],
      vValue: null,
    };
  },
  computed: {
  },
  watch: {
    // TODO : 부모의 v-model 변경을 감시(예를 들면, db로부터 데이터를 조회 한 후 값을 바인딩 할 경우)
    value() {
      this.items = this.value;
    },
  },
  /* Vue lifecycle: created, mounted, destroyed, etc */
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.items = this.value;  
  },
  beforeDestroy() {},
  destroyed() {},
  beforeUpdate() {},
  updated() {},
  /* methods */
  methods: {
    remove(state, item) {
      this.$emit('removeTag', item)
    },
    add() {
      this.$emit('addTag')
    },
    clickTag(item) {
      this.$emit('clickTag', item)
    },
    myRule(val) {
      if (this.required && !(val && val.length > 0)) {
        return ''
      }
    }
  },
};
</script>
<style lang="sass">
.customTag
  padding-bottom: 20px !important
  .material-icons
    font-size: 0.8rem
  .textCloseIcon
    display: none
  .q-field__label
    margin-left: 5px
  .q-field__native
    font-size: 0.95em !important
.customTag:hover
  .textCloseIcon
    display: block
.q-field--outlined .q-field__control:after
  border-top: 1px solid transparent !important
  border-right: 1px solid transparent !important
  border-left: 1px solid transparent !important

.customTag.q-field--filled .q-field__control
  border: 1px solid rgba(0,0,0,0.15) !important
  padding: 0px 8px 0px 2px

.customTag.q-field--dense .q-field__control, .customText.q-field--dense .q-field__marginal
  height: min-content !important
  min-height: 33px !important
</style>