<template>
  <div class="header-dots menusearch-div">
    <b-dropdown toggle-class="p-0 mr-2" menu-class="dropdown-menu-xl top-menu-search" variant="link" no-caret right>
      <span slot="button-content">
        <q-btn
          flat
          dense
          round
          color="white"
          class="topnoti"
          icon="manage_search">
        </q-btn>
      </span>
      <div class="dropdown-menu-header mb-0">
        <div class="dropdown-menu-header-inner dropdown-menu-header-inner2 bg-mini-dialog">
          <div class="menu-header-image opacity-1 dd-header-bg-2"></div>
          <div class="menu-header-content text-white">
            <!-- 메뉴명 검색 -->
            <h5 class="menu-header-title">{{$label('LBL0000854')}}</h5>
          </div>
        </div>
      </div>
      <div class="nav-justified">
        <div class="q-pa-md q-gutter-sm menusearchdivtop">
          <!-- 메뉴명 입력 후 Enter 시 메뉴가 검색됩니다. -->
          <c-text
            ref="filter"
            label=""
            :placeholder="$comm.getLangMessage('MSG0000354')"
            v-model="filter">
          </c-text>
        </div>
        <div class="scroll-area-lg" v-if="filter !== ''">
          <VuePerfectScrollbar class="scrollbar-container menusearchdivtop2">
            <!-- 일치하는 메뉴명이 없습니다. -->
            <q-tree
              ref="customTreeMenu"
              :nodes="menuTree"
              node-key="name"
              label-key="title"
              children-key="child"
              :no-results-label="$comm.getLangMessage('MSG0000353')"
              :filter="filter"
              default-expand-all
              :selected.sync="selected"
            >
              <template v-slot:header-generic="prop">
                <div class="row items-center">
                  <div class="text-weight-bold text-primary">{{ prop.node.title }}</div>
                </div>
              </template>
            </q-tree>
          </VuePerfectScrollbar>
        </div>
      </div>
    </b-dropdown>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { mapGetters } from 'vuex'

export default {
  components: {
    VuePerfectScrollbar,
  },

  data() {
    return {
      filter: '',
      selected: null,
    };
  },
  beforeMount() {
    this.init();
  },
  computed: {
    ...mapGetters([
      'permission_routers',
    ]),
    menuTree() {
      return this.permission_routers;
    },
  },
  watch: {
    selected() {
      if (this.selected !== null) {
        let data = this.$refs['customTreeMenu'].getNodeByKey(this.selected);
        if (data.href !== null) {
          if (data.href !== '') {
            if (data.href !== window.location.pathname) {
              this.filter = ''
              this.$comm.movePage(this.$router, data.href)
              this.selected = null;
              var _selectedMenu = document.getElementsByClassName('top-menu-search')[0];
              if (_selectedMenu) {
                _selectedMenu.classList.remove('show');
              }
            }
          }
        }
      }
    },
  },
  methods: {
    init() {
    },
    resetFilter () {
      this.filter = ''
    },
  }
};
</script>
<style lang="sass">
.topMenuShortDropdown
  padding-top: 0px !important
  padding-bottom: 0px !important
.toptabshort
  width: 26px
  height: 26px
  margin-right: 10px
  i
    margin-top: -8px
    margin-left: -6px
.topMenuShort
  min-width: 200px
  max-height: 289px
  *:hover
    text-decoration: none
  i
    font-size: 11px !important
  .q-item__label
    padding-left: 10px
.menusearch-div .q-tree__icon.q-mr-sm.q-icon:not(.material-icons) 
  width: 0px !important
  margin-right: 0px !important
.menusearch-div .menusearchdivtop2
  padding-left: 10px
.menusearch-div .menusearchdivtop
  padding-bottom: 0px !important
.menusearch-div .q-tree__node-header-content.col.row.no-wrap.items-center > div:not(.text-primary)
  color: #9e9e9e

</style>
