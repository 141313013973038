var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "header-dots" }, [
    _c("div", { staticClass: "header-btn-lg topfavoriteicon" }, [
      _c(
        "button",
        {
          staticClass: "material-icons star",
          class: { "is-active": _vm.showDrawerSection },
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              _vm.showDrawerSection = !_vm.showDrawerSection
            },
          },
        },
        [_vm._v("star ")]
      ),
    ]),
    _c(
      "div",
      {
        staticClass: "app-drawer-wrapper",
        class: { "drawer-open": _vm.showDrawerSection },
      },
      [
        _c(
          "div",
          { staticClass: "drawer-nav-btn right-sidebar-menu-top-btn" },
          [
            _c(
              "button",
              {
                staticClass: "material-icons clear",
                class: { "is-active": _vm.showDrawerSection },
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    _vm.showDrawerSection = !_vm.showDrawerSection
                  },
                },
              },
              [_vm._v("clear ")]
            ),
          ]
        ),
        _c(
          "div",
          { staticClass: "drawer-content-wrapper right-sidebar-menu-top" },
          [
            _c("h3", { staticClass: "drawer-heading" }, [
              _vm._v(_vm._s(_vm.$label("LBL0000861"))),
            ]),
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c(
                  "draggable",
                  _vm._b(
                    {
                      staticClass: "list-group2",
                      attrs: {
                        tag: "ul",
                        list: _vm.menufavorite,
                        handle: ".linehandle2",
                        move: _vm.checkMove,
                      },
                      on: {
                        start: function ($event) {
                          _vm.drag = true
                        },
                        end: function ($event) {
                          _vm.drag = false
                        },
                        change: _vm.moveApprLine,
                      },
                    },
                    "draggable",
                    _vm.dragOptions,
                    false
                  ),
                  [
                    _c(
                      "transition-group",
                      {
                        attrs: {
                          type: "transition",
                          name: !_vm.drag ? "flip-list" : null,
                        },
                      },
                      _vm._l(_vm.menufavorite, function (element) {
                        return _c(
                          "li",
                          {
                            key: element.sysMenuId,
                            staticClass: "list-group-item2 linehandle2",
                          },
                          [
                            _c(
                              "i",
                              { staticClass: "material-icons linehandley2" },
                              [_vm._v("reorder")]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "linetext2",
                                on: {
                                  click: function ($event) {
                                    return _vm.movePageTab(element.href)
                                  },
                                },
                              },
                              [
                                _vm._v(_vm._s(element.title) + " "),
                                _c(
                                  "q-tooltip",
                                  {
                                    attrs: {
                                      anchor: "center left",
                                      self: "center right",
                                    },
                                  },
                                  [_vm._v(_vm._s(element.title))]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "i",
                              {
                                staticClass: "material-icons lineclose2",
                                on: {
                                  click: function ($event) {
                                    return _vm.removeAt(element.sysMenuId)
                                  },
                                },
                              },
                              [_vm._v("clear")]
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }