var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "app-header header-shadow horizon-header",
        class: _vm.headerbg,
      },
      [
        _c("div", { staticClass: "logo-src" }),
        _c("div", { staticClass: "app-header__content" }, [
          _c("div", { staticClass: "app-header-left" }, [
            _c(
              "div",
              { staticClass: "header__pane ml-auto menucollapse" },
              [
                _c(
                  "b-dropdown",
                  {
                    attrs: {
                      "menu-class": "dropdown-menu-xl dropdown-menu-full",
                      variant: "link",
                      "no-caret": "",
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        attrs: { slot: "button-content" },
                        slot: "button-content",
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass:
                              "hamburger close-sidebar-btn hamburger--elastic",
                            attrs: { type: "button" },
                          },
                          [
                            _c("span", { staticClass: "hamburger-box" }, [
                              _c("span", { staticClass: "hamburger-inner" }),
                            ]),
                          ]
                        ),
                      ]
                    ),
                    _c("div", { staticClass: "horizon-full-menu-area" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "row items-center top-menu-item-group-head",
                        },
                        [
                          _c(
                            "q-btn-group",
                            { staticClass: "top-menu-item-group" },
                            _vm._l(
                              _vm.permission_routers,
                              function (topMenu, idx) {
                                return _c("q-btn", {
                                  key: idx,
                                  attrs: {
                                    icon: topMenu.icon,
                                    flat:
                                      _vm.activeMenu == topMenu.name
                                        ? false
                                        : true,
                                    unelevated:
                                      _vm.activeMenu == topMenu.name
                                        ? true
                                        : false,
                                    color: "blue-custom",
                                    size: "13px",
                                    stack: "",
                                    label: topMenu.title,
                                  },
                                  on: {
                                    mouseover: function ($event) {
                                      return _vm.linkClick(topMenu, true)
                                    },
                                  },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.secondMenus && _vm.secondMenus.length > 0
                        ? _c(
                            "ul",
                            { staticClass: "under-menu-list" },
                            _vm._l(
                              _vm.secondMenus,
                              function (sceondMenu, idx2) {
                                return _c(
                                  "li",
                                  { key: idx2, staticClass: "under-menu-item" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        class: [
                                          "sceond-menu",
                                          !sceondMenu.child
                                            ? "sceond-menu-nochild cursor-pointer"
                                            : "",
                                        ],
                                        on: {
                                          click: function ($event) {
                                            return _vm.linkClick(
                                              sceondMenu,
                                              false
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "i",
                                          { staticClass: "material-icons" },
                                          [_vm._v("arrow_right")]
                                        ),
                                        _vm._v(
                                          " " + _vm._s(sceondMenu.title) + " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "third-menu" },
                                      _vm._l(
                                        sceondMenu.child,
                                        function (thirdMenu, idx3) {
                                          return _c(
                                            "div",
                                            {
                                              key: idx3,
                                              staticClass: "third-menu-item",
                                            },
                                            [
                                              _c(
                                                "i",
                                                {
                                                  staticClass:
                                                    "material-icons under-muenu-title",
                                                },
                                                [_vm._v("arrow_right")]
                                              ),
                                              !thirdMenu.child ||
                                              thirdMenu.child.length <= 0
                                                ? _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "third-menu-button cursor-pointer",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.linkClick(
                                                            thirdMenu,
                                                            false
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            thirdMenu.title
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                : _c("span", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          thirdMenu.title
                                                        ) +
                                                        " "
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "third-menu-pop",
                                                      },
                                                      _vm._l(
                                                        thirdMenu.child,
                                                        function (
                                                          lastMenu,
                                                          idx4
                                                        ) {
                                                          return _c(
                                                            "div",
                                                            { key: idx4 },
                                                            [
                                                              _c(
                                                                "a",
                                                                {
                                                                  staticClass:
                                                                    "third-menu-button cursor-pointer",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.linkClick(
                                                                          lastMenu,
                                                                          false
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "i",
                                                                    {
                                                                      staticClass:
                                                                        "material-icons under-muenu-title",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "arrow_right"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        lastMenu.title
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    ),
                                                  ]),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                )
                              }
                            ),
                            0
                          )
                        : _vm._e(),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _c("div", { staticClass: "app-header-left" }, [_c("TabMenus")], 1),
          ]),
          _c(
            "div",
            { staticClass: "app-header-right" },
            [
              _c("MenuSearch"),
              _c("ChangeLanuage"),
              _c("ShortTabMenus"),
              _c("HeaderZoom"),
              _c("HeaderDots"),
              _c("UserArea"),
              _c("Favorites"),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "app-header__mobile-menu" }, [
          _c("div", [
            _c(
              "button",
              {
                staticClass: "hamburger close-sidebar-btn hamburger--elastic",
                class: { "is-active": _vm.isOpen },
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.toggleMobile("closed-sidebar-open")
                  },
                },
              },
              [_vm._m(0)]
            ),
          ]),
        ]),
        _c("div", { staticClass: "app-header__menu" }, [
          _c("div", { staticClass: "app-header-right" }, [_c("UserArea")], 1),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "hamburger-box" }, [
      _c("span", { staticClass: "hamburger-inner" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }