<template>
  <div
    class="v-sidebar-menu"
    :class="[!isCollapsed ? 'vsm-default' : 'vsm-collapsed', theme, rtl ? 'rtl' : '']"
    :style="{'width': sidebarWidth}"
    @mouseleave="mouseLeave"
  >
    <slot name="header" />
    <div
      class="vsm-list"
    >
      <template v-for="(item, index) in menu">
        <item
          :key="index"
          :item="item"
          :first-item="true"
          :is-collapsed="isCollapsed"
          :active-show="activeShow"
          :show-one-child="showOneChild"
          :show-child="showChild"
          :rtl="rtl"
        >
          <slot
            slot="dropdown-icon"
            name="dropdown-icon"
          />
        </item>
      </template>
    </div>
    <slot name="footer" />
    <button
      class="collapse-btn"
      :class="{'slot-icon' : $slots['collapse-icon']}"
      @click="toggleCollapse"
    >
      <slot name="collapse-icon" />
    </button>
  </div>
</template>

<script>
import Item from './CSidebarItem.vue'
// import ListItem from './CSidebarListItem.vue'
import { animationMixin } from './js/mixin'

export default {
  name: 'c-sidebar-menu',
  components: {
    Item,
    // ListItem
  },
  mixins: [animationMixin],
  props: {
    menu: {
      type: Array,
      required: true
    },
    collapsed: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '350px'
    },
    widthCollapsed: {
      type: String,
      default: '50px'
    },
    showChild: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: ''
    },
    showOneChild: {
      type: Boolean,
      default: false
    },
    rtl: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isCollapsed: this.collapsed,
      mobileItem: null,
      mobileItemPos: 0,
      mobileItemHeight: 0,
      closeTimeout: null,
      activeShow: null,
      sidebarHeight: 0
    }
  },
  computed: {
    sidebarWidth () {
      return this.isCollapsed ? this.widthCollapsed : this.width
    }
  },
  watch: {
    collapsed (val) {
      this.isCollapsed = val
      this.$nextTick(() => {
        this.initSidebarHeight()
      })
    },
  },
  created () {
    this.$on('mouseEnterItem', (val) => {
      this.mobileItem = null
      this.$nextTick(() => {
        this.mobileItem = val.item
        this.mobileItemPos = val.pos
        this.mobileItemHeight = val.height
      })
    })

    this.$on('touchClickItem', (clearCloseTimeout) => {
      if (clearCloseTimeout) {
        clearTimeout(this.closeTimeout)
        return
      }
      if (this.closeTimeout) clearTimeout(this.closeTimeout)
      this.closeTimeout = setTimeout(() => {
        this.mouseLeave()
      }, 600)
    })
  },
  mounted () {
    this.initSidebarHeight()
  },
  methods: {
    mouseLeave () {
      this.mobileItem = null
    },
    toggleCollapse () {
      this.isCollapsed = !this.isCollapsed
      this.$nextTick(() => {
        this.initSidebarHeight()
      })
      this.$emit('collapse', this.isCollapsed)
    },
    onActiveShow (uid) {
      this.activeShow = uid
    },
    onItemClick (event, item) {
      this.$emit('itemClick', event, item)
      this.$emit('item-click', event, item)
    },
    initSidebarHeight () {
      this.sidebarHeight = this.$el.offsetHeight
    }
  },
  provide () {
    return {
      emitActiveShow: this.onActiveShow,
      emitItemClick: this.onItemClick
    }
  }
}
</script>
