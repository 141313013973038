<!--
목적 : 확장검색 기능을 지원하는 컴포넌트
Detail :
 *
examples:
 *
-->
<template>
  <div class="td-input-column">
    <div v-if="!isShow" class="pad-txt-label">
      <div v-if="col.disableData && disabled && vValue == 0"
        tabindex="0" 
        ref="input-text"
        :class="['text-center']"
        @focus="numberfocus()"
        @click="numberfocus()" >
        -
      </div>
      <div v-else
        tabindex="0" 
        ref="input-text"
        :class="['text-right', disabled ? '' : 'table-td-numberlabel', colorClass? colorClass: '']"
        @focus="numberfocus()"
        @click="numberfocus()" >
        {{ vValue | toThousandFilter }}
        <!-- {{ props.row[col.name] | toThousandFilter }} -->
      </div>
    </div>
    <div v-if="editable&&isShow">
      <q-input
        ref="input-column"
        class="table-td-text"
        type="number"
        outlined autofocus
        :disable="disabled"
        v-model="vValue"
        @keyup="keyup"
        @blur="numberfocusout()"
      ></q-input>
    </div>
    <q-input
      v-show="false"
      ref="valid-input-column"
      type="number"
      :rules="[rules]"
      v-model="vValue"
    ></q-input>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import mixinCommon from './js/mixin-common'
export default {
  /* attributes: name, components, props, data */
  name: 'c-number-column',
  mixins: [mixinCommon],
  props: {
    value: {
      type: [Number, String],
      default: null,
    },
    name: {
      type: String,
    },
    colorClass: {
      type: String,
    },
    // 쓰기 권한 여부
    editable: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    col: {
      type: Object,
      default: function() {
        return {
          taskClassCd: '',
          taskKey: '',
        }
      }
    },
    props: {
      type: Object,
      default: function() {
        return { }
      }
    },
    numberOptions: {
      type: Object,
      default: () => {
        return {
          currency: null,
        }
      }
    },
    maxlength: {
      type: Number,
      default: null
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      vValue: null,
      isShow: '',
    };
  },
  computed: {
  },
  watch: {
    value() {
      if (this.value !== 0) {
        this.vValue = this.value;
      } else {
        this.vValue = null;
      }
    }
  },
  /* Vue lifecycle: created, mounted, destroyed, etc */
  beforeCreate() {},
  created() {},
  beforeMount() {
    this.init();
  },
  mounted() {
  },
  beforeDestroy() {},
  destroyed() {},
  updated() {
  },
  /* methods */
  methods: {
    init() {
      if (this.value !== 0) {
        this.vValue = this.value;
      } else {
        this.vValue = null;
      }
    },
    numberfocus() {
      if (this.editable&&!this.disabled) {
        this.$set(this.$data, 'isShow', true)
      }
    },
    numberfocusout() {
      this.$set(this.$data, 'isShow', false)
    },
    keyup() {
      let _value = this.$_.clone(this.vValue)
      if (this.maxlength > 0) {
        let _length = this.vValue ? this.vValue.length : 0;
        if (_length > this.maxlength) { 
          let substring = String(this.vValue).substring(0, this.maxlength)
          _value = substring && !isNaN(Number(substring)) ? Number(substring) : null;
        }
      }
      this.$set(this.$data, 'vValue', _value)
      this.$emit('input', _value);
      this.$emit('datachange')
    },
    rules(val) {
      return !this.required ? null : (!!val || '')
    }
  },
};
</script>