var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "v-application", attrs: { id: "benesApp" } },
    [
      _c("div", { staticClass: "app-container app-theme-white" }, [
        _c(
          "div",
          { staticClass: "app-main__outer" },
          [
            _c(
              "div",
              { staticClass: "app-main__inner p-3 mainlayoutback" },
              [_vm._t("default")],
              2
            ),
            _c(
              "transition",
              { attrs: { name: "fade", mode: "out-in", appear: "" } },
              [_c("Footer")],
              1
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }