var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "v-sidebar-menu",
      class: [
        !_vm.isCollapsed ? "vsm-default" : "vsm-collapsed",
        _vm.theme,
        _vm.rtl ? "rtl" : "",
      ],
      style: { width: _vm.sidebarWidth },
      on: { mouseleave: _vm.mouseLeave },
    },
    [
      _vm._t("header"),
      _c(
        "div",
        { staticClass: "vsm-list" },
        [
          _vm._l(_vm.menu, function (item, index) {
            return [
              _c(
                "item",
                {
                  key: index,
                  attrs: {
                    item: item,
                    "first-item": true,
                    "is-collapsed": _vm.isCollapsed,
                    "active-show": _vm.activeShow,
                    "show-one-child": _vm.showOneChild,
                    "show-child": _vm.showChild,
                    rtl: _vm.rtl,
                  },
                },
                [_vm._t("dropdown-icon", null, { slot: "dropdown-icon" })],
                2
              ),
            ]
          }),
        ],
        2
      ),
      _vm._t("footer"),
      _c(
        "button",
        {
          staticClass: "collapse-btn",
          class: { "slot-icon": _vm.$slots["collapse-icon"] },
          on: { click: _vm.toggleCollapse },
        },
        [_vm._t("collapse-icon")],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }