var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "fix-height" },
    [
      _c("q-field", {
        staticClass: "customTag",
        attrs: {
          "stack-label": "",
          filled: "",
          color: "orange-custom",
          dense: _vm.dense,
          disable: _vm.disabled || !_vm.editable,
          label: _vm.convertLabel,
          rules: [_vm.myRule],
        },
        scopedSlots: _vm._u(
          [
            _vm.label
              ? {
                  key: "label",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "row items-center all-pointer-events" },
                        [
                          _c(
                            "b",
                            [
                              !_vm.required
                                ? _c("i", {
                                    staticClass:
                                      "pe-7s-note labelfrontIcon searchAreaLabelIcon",
                                  })
                                : _vm._e(),
                              _c("font", { staticClass: "formLabelTitle" }, [
                                _vm._v(_vm._s(_vm.convertLabel)),
                              ]),
                              _vm.required
                                ? _c(
                                    "i",
                                    {
                                      staticClass:
                                        "material-icons labelfrontIcon text-requiredColor",
                                    },
                                    [_vm._v("check")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  },
                  proxy: true,
                }
              : null,
            {
              key: "control",
              fn: function () {
                return [
                  _vm._l(_vm.items, function (item, idx) {
                    return _c(
                      "q-chip",
                      {
                        key: idx,
                        attrs: {
                          outline: "",
                          square: "",
                          dense: _vm.dense,
                          removable: _vm.editable && !_vm.disabled,
                          clickable: _vm.clickable,
                          color: item["color"] ? item["color"] : "primary",
                          "text-color": "white",
                          icon: _vm.icon,
                          label: item[_vm.itemText],
                          title: item[_vm.itemText],
                        },
                        on: {
                          remove: (state) => _vm.remove(state, item),
                          click: (e) => _vm.clickTag(item),
                        },
                        model: {
                          value: item[_vm.itemValue],
                          callback: function ($$v) {
                            _vm.$set(item, _vm.itemValue, $$v)
                          },
                          expression: "item[itemValue]",
                        },
                      },
                      [_c("q-tooltip", [_vm._v(_vm._s(item[_vm.itemText]))])],
                      1
                    )
                  }),
                  _vm.editable && !_vm.disabled && _vm.isAdd
                    ? _c("q-chip", {
                        attrs: {
                          dense: _vm.dense,
                          outline: "",
                          square: "",
                          clickable: "",
                          color: "orange",
                          "text-color": "white",
                          icon: "add",
                          label: _vm.$comm.getLangLabel(_vm.addLabel),
                          title: _vm.$comm.getLangLabel(_vm.addLabel),
                        },
                        on: { click: _vm.add },
                      })
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
        model: {
          value: _vm.items,
          callback: function ($$v) {
            _vm.items = $$v
          },
          expression: "items",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }