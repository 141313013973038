var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "q-list",
        {
          staticClass: "custom-file-column",
          attrs: { bordered: "", dense: "", separator: "" },
        },
        _vm._l(_vm.fileList, function (file, idx) {
          return _c(
            "q-item",
            {
              directives: [{ name: "ripple", rawName: "v-ripple" }],
              key: idx,
              attrs: { clickable: "" },
              on: {
                click: function ($event) {
                  return _vm.fileDown(file)
                },
              },
            },
            [
              _c(
                "q-item-section",
                { attrs: { side: "" } },
                [
                  _c("q-avatar", { attrs: { rounded: "", size: "sm" } }, [
                    _c("img", { attrs: { src: _vm.setIcon(file) } }),
                  ]),
                ],
                1
              ),
              _c(
                "q-item-section",
                [
                  _c(
                    "q-item-label",
                    { staticClass: "text-primary" },
                    [
                      _vm._v(" " + _vm._s(file.oriFileNm) + " "),
                      _c(
                        "q-tooltip",
                        {
                          attrs: {
                            "content-class": "bg-red",
                            offset: [10, 10],
                          },
                        },
                        [_vm._v(" " + _vm._s(file.oriFileNm) + " ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }