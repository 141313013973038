var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.plantItems.length > 1
    ? _c("div", { staticClass: "header-dots" }, [
        _c(
          "span",
          { attrs: { slot: "button-content" }, slot: "button-content" },
          [
            _c(
              "q-btn",
              {
                staticClass: "toptabshort",
                attrs: {
                  flat: "",
                  dense: "",
                  round: "",
                  color: "white",
                  icon: "villa",
                },
              },
              [
                _c(
                  "q-tooltip",
                  { attrs: { anchor: "center left", self: "center right" } },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$label("LBL0000862")) +
                        " " +
                        _vm._s(_vm.defaultPlantName) +
                        " "
                    ),
                  ]
                ),
                _c(
                  "q-menu",
                  {
                    attrs: {
                      anchor: "bottom left",
                      self: "top middle",
                      "auto-close": "",
                      "transition-show": "jump-down",
                      "transition-hide": "jump-up",
                    },
                  },
                  [
                    _c(
                      "q-list",
                      { staticClass: "topMenuShort" },
                      _vm._l(_vm.plantItems, function (tag) {
                        return _c(
                          "q-item",
                          {
                            directives: [
                              { name: "ripple", rawName: "v-ripple" },
                            ],
                            key: tag.code,
                            ref: "tag",
                            refInFor: true,
                            class: tag.class,
                            attrs: { clickable: "", name: tag.codeName },
                            on: {
                              click: function ($event) {
                                return _vm.setDefaultPlantCd(tag)
                              },
                            },
                          },
                          [
                            _c(
                              "q-item-section",
                              [
                                _c("q-item-label", { attrs: { lines: "1" } }, [
                                  _vm._v(_vm._s(tag.codeName)),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }