<template>
  <div class="header-dots">
    <span slot="button-content zoomcontent" style="display:inline;">
      <q-btn-group outline class="zoomgroup">
        <q-btn
          flat
          size="xs"
          dense
          class="zoombtn zoomicon"
          color="white"
          icon="remove"
          @click="zoomMinus">
          <q-tooltip>
            <!-- 화면크기 축소 -->
            {{$label('LBL0000855')}}
          </q-tooltip>
        </q-btn>
        <q-btn
          flat
          dense
          size="xs"
          class="zoombtn textzoom"
          color="white"
          @click="zoomInit">
          {{zoomText}}
          <q-tooltip v-if="zoomText !== '100%'">
            <!-- 원래크기 -->
            {{$label('LBL0000856')}}
          </q-tooltip>
        </q-btn>
        <q-btn
          flat
          dense
          size="xs"
          class="zoombtn zoomicon"
          color="white"
          icon="add"
          @click="zoomPlus">
          <q-tooltip>
            <!-- 화면크기 확대 -->
            {{$label('LBL0000857')}}
          </q-tooltip>
        </q-btn>
      </q-btn-group>
    </span>
  </div>
</template>

<script>

export default {
  components: {
  },

  data() {
    return {
      zoomText: '100%',
      zoomVal: 100,
      zoomMaxVal: 115,
      zoomMinVal: 90,
    };
  },
  methods: {
    zoomInit() {
      this.zoomVal = 100;
      this.zoomText = '100%';
      document.body.style.zoom = this.zoomText;
    },
    zoomPlus() {
      if (this.zoomVal < this.zoomMaxVal) {
        this.zoomVal = this.zoomVal + 5;
        this.zoomText = this.zoomVal + '%'
        document.body.style.zoom = this.zoomText;
      }
    },
    zoomMinus() {
      if (this.zoomVal > this.zoomMinVal) {
        this.zoomVal = this.zoomVal - 5;
        this.zoomText = this.zoomVal + '%'
        document.body.style.zoom = this.zoomText;
      }
    },
  }
};
</script>
<style lang="sass">
.zoomgroup
  border: 1px solid #fff
  margin-right: 10px !important
.zoombtn
  height: 24px
  .q-btn__wrapper
    height: 24px !important
    min-height: 24px !important
  .q-btn__content
    height: 24px
    margin-top: -3px !important
  .q-btn__content
    display: flex
.zoomicon
  width: 20px
.textzoom
  width: 35px
  font-size: 0.75rem !important
  .q-btn__content
    margin-top: -5px !important
</style>

